// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }

}
.table_custom {
    box-shadow: 0 0px 2px -3px rgba(0, 0, 0, 0.2), 0 0px 2px 1px rgba(0, 0, 0, 0.14), 0 2px 2px 2px rgba(0, 0, 0, 0.12);
    thead {
        font-size : 16px;
    }
    td {
        padding : 10px 10px 0px 10px;
    }

    th {
        padding:10px;
    }

    .row-disabled{
        background-color: lightgrey;
    }
}
.align_top {
    margin-top:20px;
}
.sub-details {
    margin-top:45px;
}
.header-style{
    margin-bottom:20px;
    border-bottom: 1px solid lightgrey;
    padding-left:0px;
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {
    th, td {
      white-space: nowrap;
    }
}