// map.scss
body { font-size: 16px; }
input, select { font-size: 100%; }
.border_cls{
    border: 1px solid black;
    min-height: 100px;
    padding:10px;

    .inner_border{
        border: 1px solid black;
        min-height: 100px;
        padding: 10px;
    }
}

.no_border_cls{
    min-height: 100px;
    padding:10px;
}

.map_btn{
    width: 100%;
    margin: 10px 0px;
}

.sms_btn{
    margin-right: 10px;
}

.search-label{
    float: left;
    margin-right: 10px;
}

.toast.show {
    display: block;
    opacity: 1;
    position: absolute;
    z-index: 1;
    width: 100%;
}

.overflow_cls{
    overflow: scroll;
}
.space_cls{
    margin-bottom: 20px;
}

.custome_desc_label{
    background-color: #f3ce98;
    border:1px solid gray;
    margin-right: 3px;
    color:black;
}

.kep-login-facebook{
    margin-left: 11px;
    padding: 10px !important ;
    border-radius: 0.25em !important;
}
.tableAlignCls {
    margin-top: 20px;
    overflow: scroll;
}

.row .react-bootstrap-table-pagination{
    margin-top: 10px;
}

.awaiting_cust_signoff{
    color: orange;
    cursor: pointer;
}
.approved{
    color: green;
    cursor: pointer;
}
.cust_signoff{
    color: yellow;
    cursor: pointer;
}
.created{
    color: skyblue;
    cursor: pointer;
}

.m-signature-pad {
  position: relative;
  font-size: 10px;
  width: 400px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -10px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.m-signature-pad--body {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 60px;
  border: 1px solid #f4f4f4;
}

.m-signature-pad--body
  canvas {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  }

.m-signature-pad--footer {
  position: absolute;
  left: 40px;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.m-signature-pad--footer
  .description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
  }

.m-signature-pad--footer
  .button {
    position: absolute;
    bottom: 0;
  }

.m-signature-pad--footer
  .button.clear {
    left: 0;
  }


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"], 
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}

@media screen and (device-aspect-ratio: 9/19.5) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}

@media screen and (device-aspect-ratio: 414/896) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}
